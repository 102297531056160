import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CaminoHeader from './components/CaminoHeader';
import CaminoFooter from './components/CaminoFooter';
import CaminoCookieWarning from './components/CaminoCookieWarning';
import ContentAbout from './components/ContentAbout';
import ContentWhy from './components/ContentWhy';
import ContentWho from './components/ContentWho';
import ContentImpact from './components/ContentImpact';
import ContentCamino from './components/ContentCamino';
import ContentWork from './components/ContentWork';
import ContentPrivacy from './components/ContentPrivacy';
import ContentFAQ from './components/ContentFAQ';
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageName: '',
      pageLight: true
    }
  }

  dispatchContentPage(name, pageLight) {
    this.setState({pageName: name, pageLight: pageLight});
  }

  render() {
    return (
      <Router>
      <div className="App">
        <Switch>
            <Route exact path='/' render={(props) => <ContentAbout {...props} onContentPage={this.dispatchContentPage.bind(this)} showPreRoll={true} />} />
            <Route path='/about' render={(props) => <ContentAbout {...props} onContentPage={this.dispatchContentPage.bind(this)} showPreRoll={false} />} />
            <Route path="/why" render={(props) => <ContentWhy {...props} onContentPage={this.dispatchContentPage.bind(this)} />} />
            <Route path="/who" render={(props) => <ContentWho {...props} onContentPage={this.dispatchContentPage.bind(this)} />} />
            <Route path="/impact" render={(props) => <ContentImpact {...props} onContentPage={this.dispatchContentPage.bind(this)} />} />
            {/* <Route path="/involved" render={(props) => <ContentInvolved {...props} onContentPage={this.dispatchContentPage.bind(this)} />} /> */}
            <Route path="/camino" render={(props) => <ContentCamino {...props} onContentPage={this.dispatchContentPage.bind(this)} />} />
            <Route path="/work" render={(props) => <ContentWork {...props} onContentPage={this.dispatchContentPage.bind(this)} />} />
            <Route path="/privacy" render={(props) => <ContentPrivacy {...props} onContentPage={this.dispatchContentPage.bind(this)} />} />
            <Route path="/faq" render={(props) => <ContentFAQ {...props} onContentPage={this.dispatchContentPage.bind(this)} />} />
        </Switch>

        <CaminoHeader pageName={this.state.pageName} pageLight={this.state.pageLight} />
        <CaminoCookieWarning />
        <CaminoFooter pageName={this.state.pageName} />
      </div>
      </Router>
    );
  }
}

export default App;