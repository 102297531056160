import React, { Component } from 'react';
import cookie from 'react-cookies';
import CloseIcon from '@material-ui/icons/Close';

class CaminoCookieWarning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warningAccepted: cookie.load('warningAccepted') || false,
      show: false
    };    
    this.height = 0;
    this.closeWarning = this.closeWarning.bind(this);
  }

  componentDidMount() {    
    if (!this.state.warningAccepted) {
      setTimeout(function() {

        this.setState({show: true});

      }.bind(this), 1000)
    }

    this.height = this.divElement.clientHeight;
  }

  closeWarning() {
    cookie.save('warningAccepted', true, { path: '/' })
    this.setState({warningAccepted: true, show: false});
  }

  render() {
    const { show } = this.state;
    let styles = {}, warningClass = '';

    if (this.height) {
      // update height as it may have changed
      this.height = this.divElement.clientHeight;

      warningClass = 'active';
      if (show) {
        styles = {
          top: 'auto',
          bottom: 0
        };
      }
      else {
        styles = {
          top: 'auto',
          bottom: '-'+this.height+'px'
        };
      }
    }

    return (
      <div style={styles} className={`caminoCookieWarning ${warningClass}`} ref={ (divElement) => this.divElement = divElement}>
        <div className="content">
          <div className="text">
          We use cookies on this website to make your experience better. By continuing to browse you agree to our use of cookies. If you do not consent to the use of cookies, please alter your settings. To find out more please refer to our privacy and cookie policy pages.
          </div>
          <CloseIcon  className="closeBtn" onClick={this.closeWarning} />
        </div>
      </div>
    );
  }
}

export default CaminoCookieWarning;
