import React from 'react';
import { Link } from 'react-scroll'

class CaminoJumpToSection extends React.Component {    
  render () {
    return (
      <Link className="link" to={this.props.to} smooth={true} duration={1000} onClick={this.props.onClick}>
      {this.props.children}
      </Link>
    )
  }
} 

export default CaminoJumpToSection;