import React, {Component} from 'react';
import { Link } from "react-router-dom";
import * as Scroll from 'react-scroll';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as LogoRed } from '../../media/logo_camino_red.svg';
import { SocialLinks } from './SocialLinks'

const styles = {
  socialLinks: {
    marginBottom: 20
  }
}

class CaminoFooter extends Component {
  constructor(props) {
    super(props);

    this.scrollToTop = this.scrollToTop.bind(this);    
  }

  scrollToTop (e) {
    var scroll = Scroll.animateScroll;

    var options = {duration: 0};
    scroll.scrollToTop(options);
  }

  render() {
    const pageName = this.props.pageName;

    return (
      <footer>
      <div className="inner">
        <Hidden smDown>
          <div className="large">
            <div className="left">
              <div className="content">
                <LogoRed className="logo" width="221px" height="63px" />

                <div className="copyright">© Camino 2019. Join us.</div>
              </div>
            </div>
            <div className="right">
              <h1>Social for the world</h1>
              <div className="block">
                <div className="left">
                <p>
                Let’s talk<br/>
                <a href="mailto:bjorn@caminosocial.org">bjorn@caminosocial.org</a>
                </p>
                <p>
                Phone<br/>
                +61[0]435 453 841
                </p>
                <p>
                GPO Box 1528<br/>
                Adelaide<br/>
                South Australia<br/>
                Australia 5001
                </p>
                </div>
                <div className="right">
                  <div className="content">
                    <p>
                    <Link className={pageName === 'camino' ? 'active' : ''} to="/camino" onClick={this.scrollToTop}>About Camino</Link><br/>
                    <Link className={pageName === 'faq' ? 'active' : ''} to="/faq" onClick={this.scrollToTop}>Principles</Link><br/>
                    <Link className={pageName === 'work' ? 'active' : ''} to="/work" onClick={this.scrollToTop}>Work with us</Link><br/>
                    <Link className={pageName === 'privacy' ? 'active' : ''} to="/privacy" onClick={this.scrollToTop}>Privacy policy</Link><br/>
{/* 
            <Link to="/involved">CrowdFunding</Link>
*/}            
                    </p>
                    <SocialLinks />
                    <div className="fix-bottom">
                    <p>
                    Site by <a href="http://rodeo.com.co/" target="_blank" rel="noopener noreferrer">Rodeo</a>
                    </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="terms">Camino® is a not-for-profit company where 100% of profit is invested into climate action by planting trees. Good for people, good for planet. </div>

            </div>
          </div>
        </Hidden>

        <Hidden mdUp>
          <div className="small">
            <h1>Social for<br/> the world</h1>
            <p>
            Let’s talk<br/>
            <a href="mailto:bjorn@caminosocial.org">bjorn@caminosocial.org</a>
            </p>
            <p>
            Phone<br/>
            +61[0]435 453 841
            </p>
            <p>
            GPO Box 1528<br/>
            Adelaide<br/>
            South Australia<br/>
            Australia 5001
            </p>
            <p>
            <Link className={pageName === 'camino' ? 'active' : ''} to="/camino" onClick={this.scrollToTop}>About Camino</Link><br/>
            <Link className={pageName === 'work' ? 'active' : ''} to="/work" onClick={this.scrollToTop}>Work with us</Link><br/>
            <Link className={pageName === 'privacy' ? 'active' : ''} to="/privacy" onClick={this.scrollToTop}>Privacy policy</Link><br/>
            <Link className={pageName === 'faq' ? 'active' : ''} to="/faq" onClick={this.scrollToTop}>Principles</Link><br/>
{/* 
            <Link to="/involved">CrowdFunding</Link>
*/}            
            </p>
            <SocialLinks
              className={this.props.classes.socialLinks}
            />
            <p>
            Site by Rodeo
            </p>

            <div className="terms">Camino® has been devised to support reforestation, girls' education and Indigenous conservation through a network of global partners — Camino is good for people, good for our planet.</div>

            <LogoRed className="logo" width="221px" height="63px" />
            <div className="copyright">
            © Camino 2019. Join us.
            </div>
          </div>
        </Hidden>
      </div>
      </footer>
    );
  }
}

export default withStyles(styles)(CaminoFooter)
