/*global ga*/
import { useEffect } from 'react'
import PropTypes from 'prop-types'

export const PageTracker = (props) => {
  const { path } = props
  useEffect(() => {
    if (ga) {
      ga('set', 'page', path);
      ga('send', 'pageview');
    } else {
      console.warn(`Could not send page hit metric for ${path}, no ga() fn found on window`)
    }
  }, [path])
  return null
}

PageTracker.propTypes = {
  path: PropTypes.string
}
