import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  // Look at here: applied specific styles to resizing and background
  expansion: {
    boxShadow: 'none',
    background: 'none',
    '&:before': {
       display: 'none'
     }
  },
  summary: {
    borderTop: '2px solid #000',
    background: 'none',
    padding: 0
  },
  emphasized: {
    fontWeight: 'bold'
  },
  details: {
    padding: 0    
  }
});

const FAQItem = ({ question, answer, classes, bold }) => {
  const className = bold
    ? `${classes.summary} ${classes.emphasized}`
    : classes.summary
  return <ExpansionPanel className={classes.expansion}>
    <ExpansionPanelSummary
      className={className}
      expandIcon={<ExpandMoreIcon />}
      IconButtonProps={{
        disableRipple: true
      }}
    >{question}</ExpansionPanelSummary>
    <ExpansionPanelDetails className={classes.details}>{answer}</ExpansionPanelDetails>
  </ExpansionPanel>
}

class CaminoFAQ extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className="caminoFAQ">
        <FAQItem
          question="Why should I sign up?"
          answer="You should sign up because the smallest things – like participating in an ethical social network – can change a life. Because you want your social media platform to be ethical and honest. Because you want to do good for other people and our planet."
          classes={classes}
          bold
        />
        <FAQItem
          question="When is this happening?"
          answer="A prototype is in development. Exciting things are happening. We’ll keep you up to date via email or (and, yes, we know this is ironic) follow us on Twitter, Facebook or Instagram."
          classes={classes}
          bold
        />
        <FAQItem
          question="How much does this cost?"
          answer={
            <React.Fragment>
              <p>The Camino app is free to download.</p>
              <p>You’re welcome to use the platform for free. We just ask that you opt in to see a moderate amount of advertising for services or products in categories that you choose.</p>
              <p>Or you may prefer to pay a monthly subscription. By supporting us with an ongoing monthly donation of $1 or $2 means you can opt out of receiving any promotional content. You choose which one – there is no difference to the service you receive. However, this small increase can make a huge difference on meeting our impact goals.</p>
              <p>Whatever way you choose to support Camino, you’re helping globally important conservation and social projects.</p>
            </React.Fragment>
          }
          classes={classes}
          bold
        />
        <FAQItem
          question="Where does the money go?"
          answer={
            <>
              <p>Camino is designed to give back to society. The main difference to other social media companies is that we commit 100% of Camino's profits to climate action to reduce global warming.</p>
              <p>Camino will always exist 100%, to bring people together, to participate and contribute to reducing global warming by caring for other people and the planet.</p>
            </>
          }
          classes={classes}
          bold
        />
        <FAQItem
          question="Is the platform any good?"
          answer="Like other social media, Camino lets you communicate with and develop networks. However, we don’t use your data or serve you ads without your explicit consent. We also work hard to protect your information and the platform from external attacks. Our Defence in Depth (DiD) architecture supports layers of defence to reduce vulnerabilities and help protect, detect and react to attacks."
          classes={classes}
          bold
        />
        <FAQItem
          question="So, no algorithms, what?"
          answer="Well, we do use algorithms, but only for the benefit of Camino users. We don’t use algorithms to manipulate users for marketing purposes or to prioritise content. The order in which content appears in your feed is based solely on when it was posted."
          classes={classes}
          bold
        />
      </div>
    );
  }
}

export default withStyles(styles)(CaminoFAQ);
