import React, { Component } from 'react';
import CaminoSubscribe from './CaminoSubscribe';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import { PageTracker } from './PageTracker';

class ContentPrivacy extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.props.onContentPage('privacy', true);
  }

  render() {
    return (
      <div className="page">

        <PageTracker path="/privacy" />

        <div className="caminoContent privacy-policy">
          <section>
            <div className="inner">

            <div className="message">
       <h2>We believe in protecting<br/>user’s psychological,<br/>emotional, intellectual,<br/>digital and physical safety. </h2>
            </div>

            <h1 className="heading">Privacy Policy</h1> 
	    
	    <div className="break"></div>
	    
            <h2> Our commitment to your privacy</h2> 
            <p className="policy">
            Camino takes your privacy very seriously. 
When handling your personal information we adhere to our obligations under the Privacy Act 1988 and comply with the Australian Privacy Principles, as well as any other necessary State Legislation. 
            </p>
	    
	    <div className="break"></div>
	    
	    <h2> Securing your information</h2> 
            <p className="policy">
            We don’t use your data to serve you ads without your explicit consent. 
We work hard to protect your information and the platform from external attacks. Our Defence in Depth (DiD) architecture supports layers of defence to reduce vulnerabilities and help protect, detect and react to attacks. 
Your personal and sensitive information is held securely to protect it against loss, misuse or unauthorised access, destruction, use, modification or disclosure. We will destroy any personal information once it is no longer necessary for us to hold it for any business or legal reasons. This includes permanently de-identifying personal information when possible to safe guard you as best we can.  
            </p>

	    <div className="break"></div>
	    
	    <h2>Anonymity </h2> 
            <p className="policy">
            If you want to be handled anonymously, you can be, as it’s your right, so long as it’s in a lawfully practicable manner. 
If you do request anonymity we will do our best to respect this, however sometimes this may make communication with you difficult, in which case we will be in touch with you.   
            </p>

	   <div className="break"></div>
	  
	   <h2> Cookies and links to other websites </h2> 
            <p className="policy">
              Cookies:<br/>
    When you visit Camino a record of your visit will be logged. 
    The following data will be provided by your browser:
            <ul className="list">
              <li>Your IP address and/or domain name</li>
              <li>Your operating system (type of browser and platform)</li>
              <li>The date, time and length of your visit</li>
              <li>The resources you accessed and documents you downloaded.</li>
            </ul>
            </p>
	          <p>
            The information outlined above is used to compile statistical information about the use of Camino, and not for any other purpose.
It’s your choice if you would like cookies to be used — you can change your browser settings to disable them anytime. 
            </p>
	           <p>
            Links to other websites:<br/>
Camino may include links to third party websites, and third party websites may have links to Camino. Unfortunately we can’t control third party operators collection of your personal information as our privacy policy does not extend to them. It is your responsibility to read the privacy policy of any website you link from Camino.  
            </p>

           <div className="break noline"></div>

            <CaminoSubscribe />

            </div>
          </section>

          <section>
            <div className="inner">
              <CaminoJumpToTopButton />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContentPrivacy;
