import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ReCAPTCHA from "react-google-recaptcha";

const theme = createMuiTheme({
  props: {
    MuiInput: { inputProps: { spellCheck: 'false' } }
  },  
  typography: {
    useNextVariants: true,
    fontFamily: '"Helvetica", sans-serif'
  },  
  palette: {
    primary: {
      main: '#FFF'
    },
    secondary: {
      main: '#D2232A'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 'bold',
        borderRadius: 0
      },
      contained: {
        boxShadow: 'none'
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20
  }
}))

const API_URL = process.env.REACT_APP_CAMINO_API_URL;
const RECAPTCHA_ENABLED = process.env.RECAPTCHA_ENABLED;

const DEFAULT_MSG_STATE = {
  statusInfo: '',
  statusErr: ''
}

const submit = (setMessages, captchaResponse) => async (e) => {
  e.preventDefault();
  setMessages(DEFAULT_MSG_STATE)

  const data = new FormData(e.target)
  const email = data.get('subscriberEmail')
  const res = await fetch(
    `${API_URL}/subscribe`,
    {
      method: "POST",
      headers: { "Content-Type": "text/plain" },
      // TODO: send captcha response
      body: JSON.stringify({ email, captchaResponse })
    }
  )
  const json = await res.json()

  switch (true) {
    case res.status === 200:
      setMessages({ statusInfo: 'Thanks for registering your interest.' });
      break;
    case !!json.error && /member exists/i.test(json.error):
      setMessages({ statusErr: "Looks like you've already registered.  Thanks, we'll be in touch." })
      break
    default:
      setMessages({ statusErr: 'Sorry, something went wrong.  Please contact us by email or check your connection and try again.' });
      break;
  }
}

const handleCaptchaChange = (setCaptchaResponse) => (value) => {
  setCaptchaResponse(value)
}

const CaminoSubscribe = (props) => {
  const [messages, setMessages] = useState(DEFAULT_MSG_STATE)
  const [captchaResponse, setCaptchaResponse] = useState(null)
  const {
    statusInfo,
    statusErr,
  } = messages
  const classes = useStyles(props)
  return (
    <div className="caminoSubscribe" name="page-subscribe">

      <h2>Interested? Curious?<br />
        You want this?</h2>
      <h2>Stay in the loop. Be involved.</h2>

      <form onSubmit={submit(setMessages, captchaResponse)}>

        <TextField
          label="Your email"
          name="subscriberEmail"
          required
          autoComplete="off"
        />

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleCaptchaChange(setCaptchaResponse)}
          className={classes.root}
        />

        <div className="subscribe">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!captchaResponse && RECAPTCHA_ENABLED}
          >Register Here</Button>
        </div>

        <div className="status">
          <div className={`info ${statusInfo ? "focus" : ""}`}>{statusInfo}</div>
          <div className={`error ${statusErr ? "focus" : ""}`}>{statusErr}</div>
        </div>

      </form>

    </div>

  )
}

const CaminoSubscribeWrapper = (props) => (
  <MuiThemeProvider theme={theme}>
    <CaminoSubscribe />
  </MuiThemeProvider>
)

export default CaminoSubscribeWrapper;
