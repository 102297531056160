import React from 'react';
import * as Scroll from 'react-scroll';

class CaminoJumpToTopButton extends React.Component {    
  constructor(props) {
    super(props);

    this.scrollToTop = this.scrollToTop.bind(this);    
  }

  scrollToTop (e) {
    var scroll = Scroll.animateScroll;

    scroll.scrollToTop();
  }

  render () {
    return (
      <div className='caminoJumpToTop' onClick={this.scrollToTop}>Top</div>
    )
  }
} 

export default CaminoJumpToTopButton;