import React, { Component } from 'react';
import CaminoSubscribe from './CaminoSubscribe';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import { PageTracker } from './PageTracker';

class ContentCamino extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.props.onContentPage('camino', true);
  }

  render() {
    return (
      <div className="page">

        <PageTracker path="/camino" />

        <div className="caminoContent">
          <section>
            <div className="inner">

            <div className="message">
            <h2>We know that fairness,<br/>equality, justice and climate<br/>responsibility lie at the heart<br/>of our collective futures. </h2>
            </div>

            <h1>About Camino</h1>

            <p>
            Camino is an ethical social platform that brings people together to collectively contribute to reversing global warming.
            </p>

            <div className="image"><img src="https://camino.imgix.net/About_image_01.jpg" alt="Placeholder" /></div>

            <p>We are a small team of passionate humans working closely together from the ground up, united by a shared vision and drive to tackle the biggest moral challenge of our time through an impact driven contribution to reversing climate change. We are doing this by creating a mobile first social networking platform that is responsible, honest and makes a difference — a platform that does good for people and our planet.</p>
             <p>We believe planting trees, creating carbon credits and conserving forests around the world provides real solutions for our climate and biodiversity crisis. Your swipe or click can result in multiplying positive change, literally! And with your support we have the ability to generate real transformation which we believe will go a long way to not only protecting, but regenerating life on this amazing planet we humans all call home.</p>
            <p>
            Join us.
            </p>

            <div className="break noline"></div>

          
            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break noline"></div>

            <CaminoSubscribe />

            </div>
          </section>

          <section>
            <div className="inner">
              <CaminoJumpToTopButton />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContentCamino;
