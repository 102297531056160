import React, {Component} from 'react';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

class CaminoVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <div className='caminoVideo'>
        <Video className='react-player'
          controls={[]}
          poster="https://camino.imgix.net/CAMINO-WEB_SML_still-image.png?fm=jpg&w=1280&h=720&fit=crop&q=80"
          onCanPlayThrough={() => {
              // Do stuff
          }}>
          <source src="https://camino.imgix.net/CAMINO WEB_SML_LATEST.mp4" />
        </Video>
      </div>
    );
  }
}

export default CaminoVideo;
