import React, {Component} from 'react';
import { Link } from "react-router-dom";
import * as Scroll from 'react-scroll';
import CaminoJumpToSection from './CaminoUtils/CaminoJumpToSection';
import { ReactComponent as LogoWhite } from '../media/logo_camino.svg';
import { ReactComponent as LogoBlack } from '../media/logo_camino_black.svg';
import { ReactComponent as MenuHamburgerWhite } from '../media/menu_hamburger.svg';
import { ReactComponent as MenuHamburgerBlack } from '../media/menu_hamburger_black.svg';
import { ReactComponent as MenuClose } from '../media/menu_close.svg';

class CaminoHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlay: false
    }
    this.showOverlay = this.showOverlay.bind(this);    
    this.hideOverlay = this.hideOverlay.bind(this);    
  }

  showOverlay() {
    this.setState({overlay: true});
  }

  hideOverlay() {
    this.setState({overlay: false});
  }

  scrollToTop (e) {
    var scroll = Scroll.animateScroll;

    var options = {duration: 0};
    scroll.scrollToTop(options);
  }

  render() {
    const overlay = this.state.overlay;
    const pageLight = this.props.pageLight;
    const pageName = this.props.pageName;

    return (
      <div>
      <header className="max">
        <div className="logo">            
          <Link to="/about" onClick={this.scrollToTop}><LogoBlack width="122px" height="32px" /></Link>
        </div>

        <div className="menus">
          <ul>
            <li className={pageName === 'why' ? 'active' : ''}><Link to="/why" onClick={this.scrollToTop}>Why do this?</Link></li>
            <li className={pageName === 'who' ? 'active' : ''}><Link to="/who" onClick={this.scrollToTop}>Who we are</Link></li>
            <li className={pageName === 'impact' ? 'active' : ''}><Link to="/impact" onClick={this.scrollToTop}>Impact goals</Link></li>
            <li className={pageName === 'faq' ? 'active' : ''}><Link to="/faq" onClick={this.scrollToTop}>Principles</Link></li>
            <li className={pageName === 'camino' ? 'active' : ''}><Link  to="/camino" onClick={this.scrollToTop}>About Camino</Link></li>            
            <li><CaminoJumpToSection to={'page-subscribe'}>Be involved</CaminoJumpToSection></li>
          </ul>
        </div>
      </header>

      <header className="mini">
        <div className={`overlay ${overlay ? 'open' : ''}`}>
          <div className="menus">
            <ul>
              <li className={pageName === 'why' ? 'active' : ''}><Link to="/why" onClick={this.hideOverlay}>Why do this?</Link></li>
              <li className={pageName === 'who' ? 'active' : ''}><Link to="/who" onClick={this.hideOverlay}>Who we are</Link></li>
              <li className={pageName === 'impact' ? 'active' : ''}><Link to="/impact" onClick={this.hideOverlay}>Impact goals</Link></li>
              <li className={pageName === 'camino' ? 'active' : ''}><Link  to="/camino" onClick={this.hideOverlay}>About Camino</Link></li>
              <li className={pageName === 'faq' ? 'active' : ''}><Link to="/faq" onClick={this.hideOverlay}>Principles</Link></li>
              <li className={pageName === 'work' ? 'active' : ''}><Link to="/work" onClick={this.hideOverlay}>Work with us</Link></li>
              <li className={pageName === 'privacy' ? 'active' : ''}><Link to="/privacy" onClick={this.hideOverlay}>Privacy policy</Link></li>
              <li><CaminoJumpToSection to={'page-subscribe'} onClick={this.hideOverlay}>Be involved</CaminoJumpToSection></li>
            </ul>
          </div>

          <div className="close" onClick={this.hideOverlay}>
          <MenuClose width="27px" height="18px" />
          </div>
        </div>

        <div className="logo">            
          <Link to="/about">
          {pageLight &&
          <LogoBlack width="122px" height="32px" />
          }
          {!pageLight &&
          <LogoWhite width="122px" height="32px" />
          }
          </Link>
        </div>
        <div className="menu">
          <span onClick={this.showOverlay}>
          {pageLight &&
          <MenuHamburgerBlack width="27px" height="16px" />
          }
          {!pageLight &&
          <MenuHamburgerWhite width="27px" height="16px" />
          }
          </span>
        </div>
      </header>
      </div>
    );
  }
}

export default CaminoHeader;
