import React, { Component } from 'react';
import CaminoSubscribe from './CaminoSubscribe';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import { PageTracker } from './PageTracker';

class ContentWork extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.props.onContentPage('work', true);
  }

  render() {
    return (
      <div className="page">

        <PageTracker path="/work" />

        <div className="caminoContent">
          <section>
            <div className="inner">

            <div className="message">
            <h2>Are you motivated to drive large-scale global impact through re-aligning social technology for good?</h2>
            </div>

            <h1>Work with us</h1>
            <p>We put people first and believe diverse life experiences and a values driven culture informs strategic thinking.</p>
            <div className="image"><img src="https://camino.imgix.net/Work_image_02.jpg" alt="Work" /></div>
            <p>
            Camino strives to be exceptional across the board, in everything we do. We acutely recognise the importance of building a diverse and committed community of specialists who have a passion to contribute to large-scale global impact and care deeply about our core objective — reversing climate change. If you have a burning desire to contribute more, make a difference and do more good in the world, then get in touch and let us know how you can help Camino rise to this challenge, we want to hear from you. 
            </p>
          
          </div>
          </section>
      
          <section>
            <div className="inner">
            <div className="break noline"></div>

            <CaminoSubscribe />

            </div>
          </section>

          <section>
            <div className="inner">
              <CaminoJumpToTopButton />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContentWork;
