import React from 'react'
import { makeStyles } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

const useStyles = makeStyles(() => ({
  root: {
    marginRight: 8,
    '&:hover': {
      color: '#000',
      cursor: 'pointer'
    }
  }
}))

const launchSocialSite = (code) => {
  let url
  switch (code) {
    case 'facebook':
      url = 'https://www.facebook.com/caminosocial'
      break
    case 'twitter':
      url = 'https://twitter.com/camino_social'
      break
    case 'instagram':
      url = 'https://www.instagram.com/Camino_social/'
      break
    case 'linkedin':
      url = 'https://www.linkedin.com/company/caminosocial'
      break
    default:
      console.error('No social site for', code)
  }
  if (url) {
    window.open(url, '_blank')
  }
}

export const SocialLinks = (props) => {
  const classes = useStyles(props)
  return <div className={props.className}>
    <FacebookIcon
      className={classes.root}
      onClick={() => launchSocialSite('facebook')}
    />
    <TwitterIcon
      className={classes.root}
      onClick={() => launchSocialSite('twitter')}
    />
    <InstagramIcon
      className={classes.root}
      onClick={() => launchSocialSite('instagram')}
    />
    <LinkedInIcon
      className={classes.root}
      onClick={() => launchSocialSite('linkedin')}
    />
  </div>
}

SocialLinks.defaultProps = {
  className: ''
}