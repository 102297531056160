import React, {Component} from 'react';
import { ReactComponent as Logo } from '../media/logo_camino.svg';

class CaminoPreRoll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showState: 0
    };    
  }

  componentDidMount() {
    setTimeout(function() {

      this.setState({showState: 1});

      setTimeout(function() {

        this.setState({showState: 2});

        setTimeout(function() {

          this.setState({showState: 3});

          setTimeout(function() {

            this.setState({showState: 4});

          }.bind(this), 1000)    

        }.bind(this), 1000)    

      }.bind(this), 2000)    

    }.bind(this), 1000)    
  }

  render() {
    const { showState } = this.state;

    var showBrandClass = '', showPreRollClass = '';

    switch (showState) {
      case 1:
        showBrandClass = 'moveOn focus';
        break;

      case 2:
        showBrandClass = 'moveOn';
        break;

      case 3:
        showBrandClass = 'moveOn';
        showPreRollClass = 'blur';
        break;

      case 4:
        showBrandClass = 'moveOn';
        showPreRollClass = 'blur hide';
        break;

      default:
        break;
    }

    return (
      <div className={`caminoPreRoll ${showPreRollClass}`}>
        <div className="brandContainer">
          <div className={`brand ${showBrandClass}`}>
          <Logo width="196px" height="55px" />
          <div className="msg">Social for the world</div>
          </div>  
        </div>
      </div>
    );
  }
}

export default CaminoPreRoll;