import React, { Component } from 'react';
import CaminoSubscribe from './CaminoSubscribe';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import { PageTracker } from './PageTracker';

class ContentFAQ extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.props.onContentPage('faq', true);
  }

  render() {
    return (
      <div className="page">

        <PageTracker path="/faq" />

        <div className="caminoContent">
          <section>
            <div className="inner">

              <div className="message">
                <h2>Camino’s ethical lens<br/>and principles for<br/>Humane Technology </h2>
              </div>

             

              <div className="break"></div>

              <h2>Wellbeing</h2>
              <p>
                Aligning system goals and incentives in the best interest of humanity.
    Wellbeing can be enhanced through the following principles:
                <ul className="list">
                  <li>The user’s best interests guide the system goals.</li>
                  <li>The user is informed and made aware of the system goals.</li>
                  <li>Habits and user experiences are designed to enable competency and connection.</li>
                  <li>The business model is built to support the human outcomes of the solution.</li>
                </ul>
              </p>

              <div className="break"></div>

              <h2>Inclusion</h2>
              <p>
                Adapting to the varied capabilities of the user, embracing diversity and creating a sense of belonging. This extends beyond the abilities of the user, adjusting for factors such as digital literacy, and structural inequalities.
                Inclusion can be enhanced through the following principles:
                <ul className="list">
                  <li>Diverse capabilities of the target user base are mapped and accounted for in the system design.</li>
                  <li>Different groups of users are represented in the dataset used to train the algorithms for the benefit of users. </li>
                  <li>Extra attention is paid to addressing inequalities when incorporating vulnerable communities into the service.</li>
                  <li>Target users’ perspectives are sought and incorporated into the system design.</li>
                  <li>The team has representatives from the target groups.</li>
                </ul>
              </p>

              <div className="break"></div>

              <h2>Privacy</h2>
              <p>
                Honouring the user’s ownership of their information in the way it is collected, analysed, processed, interpreted and shared.
                Privacy is enhanced through the following principles:
                <ul className="list">
                  <li>The user owns their own data.</li>
                  <li>The user controls who has access to their data.</li>
                  <li>The user is informed about how their data is used.</li>
                  <li>User’s permission is acquired when access changes.</li>
                </ul>
              </p>

              <div className="break"></div>

              <h2>Security</h2>
              <p>
                Protecting the user’s psychological, emotional, intellectual, digital and physical safety. Security is enhanced through the following principles:
                <ul className="list">
                  <li>Sensitive data is stored in separate, highly secure databases.</li>
                  <li>Failsafes are in place in the event of technical/system failure.</li>
                  <li>Security vulnerabilities are proactively explored addressed.</li>
                  <li>Measures and procedures are in place to alert users to help with contingencies in the event of a data breach or hack.</li>
                </ul>
              </p>

              <div className="break"></div>

              <h2>Accountability</h2>
              <p>
                Creating transparency in how decisions are made, biases are addressed and creating pathways for the user to have meaningful insights. Accountability can be enhanced through the following principles:
                <ul className="list">
                  <li>Biases are tested for and addressed.</li>
                  <li>The decision-making process can be explained in a manner that the user can understand.</li>
                  <li>Avenues are in place to seek feedback and further information.</li>
                </ul>
              </p>

              <div className="break"></div>

              <h2>Trust</h2>
              <p>
                Creating a reliable environment that promotes authentic engagement. Trust can be enhanced through the following principles:
                <ul className="list">
                  <li>The content, entities or claims are verified for authenticity.</li>
                  <li>The product and service provided is trustworthy in the eyes of the user.</li>
                  <li>The company’s stance or principles are accessible to the public.</li>
                </ul>
              </p>

              <div className="break"></div>

              <h2>Collective impact</h2>
              <p>
                Creating a platform with the spirit of communication and community is at the heart of Camino. It provides a range of impact opportunities, including:
                <ul className="list">
                  <li>Individual impact on climate change.</li>
                  <li>Collective impact on climate change.</li>
                  <li>Pathways for meaningful engagement in impact project stories.</li>
                  <li>Mapped contributions and project impact outcomes.</li>
                  <li>An ethical and humane social networking experience.</li>
                </ul>
              </p>

            </div>
          </section>

          <section>
            <div className="inner">
              <div className="break noline"></div>

              <CaminoSubscribe />

            </div>
          </section>

          <section>
            <div className="inner">
              <CaminoJumpToTopButton />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContentFAQ;
