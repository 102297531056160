import React, { Component } from 'react';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import CaminoSubscribe from './CaminoSubscribe';
import { PageTracker } from './PageTracker';

class ContentWhy extends Component {
  constructor(props) {
    super(props);

    this.props.onContentPage('why', true);
  }

  render() {
    return (
      <div className="page">

        <PageTracker path="/why" />

        <div className="caminoContent">
          <section>
            <div className="inner">

            <div className="message">
            <h2>There is a contribution<br/>waiting in all of us. This is<br/>our focus.</h2>
            </div>

            </div>
            </section>
      
            <section>
            <div className="inner">
      
            <h1><br/>
            Reforestation</h1>
      
            <p>Creating forests out of wastelands — something to tell your grandkids about.</p>

            <div className="image"><img src="https://camino.imgix.net/02_03-Reforestation.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>

            <div className="break"></div>

            <h1>Why</h1>
            <p>The Earth loses 7.6 million hectares of forests per year, equal to 27 soccer fields every minute. It’s estimated that 15% of all greenhouse gas emissions come from deforestation. Turning wastelands into forests is something we want to tell our grandkids about. Along with storing carbon, regenerating soil and preventing salinisation, afforestation supports biodiversity and can provide livelihoods for farmers through selective timber harvesting, agroforestry and silvopasture.</p>

            <div className="details">
              <div className="col">
                <table>
                  <thead>
                    <tr>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>The Earth loses 18.7 million acres of forests per year.</td>
                    </tr>
                    <tr>
                      <td>15% of all greenhouse gas emissions come from deforestation.</td>
                    </tr>
                    <tr>
                      <td>Through natural regrowth, committed land for tropical forests could sequester a total of 61.2 gigatons of carbon dioxide by 2050. (Only carbon stored in soil, organic matter and aboveground biomass is accounted for).</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Establishing timber plantations on an additional 204 million acres of marginal lands could sequester 18.1 gigatons of carbon dioxide by 2050.</td>
                    </tr>
                    <tr>
                      <td>1.4 billion additional acres are candidates for restoration or reforestation</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="break"></div>

            <h1>How we measure success</h1>
            <p>The number of square kilometres of wasteland turned into forest. The number of trees planted. The number of tonnes of CO2 sequestered.</p>
            <p>We cannot make change alone. Our trusted reforestation partners share a common vision and provide high impact on the ground programs around the world.</p>
      <p className="credit">
            Confirmed partners:
            </p>

            <div className="partners">
              <div className="partner"><a href="https://edenprojects.org" target="_blank" rel="noopener noreferrer"><img src="https://camino.imgix.net/Why_01_EdenProjects.png" alt="Partner" /></a></div>
              <div className="partner"><a href="https://www.weforest.org" target="_blank" rel="noopener noreferrer"><img src="https://camino.imgix.net/Why_01_WeForest.png" alt="Partner" /></a></div>
              <div className="partner"><a href="www.seedballskenya.com" target="_blank" rel="noopener noreferrer"><img src="https://camino.imgix.net/Why_01_SeedBall.png" alt="Partner" /></a></div>
            </div>

            </div>
            </section>
            
            <section>
            <div className="inner">
            <div className="break page noline"></div>
            <h1><br/>
            Indigenous<br/>
            conservation</h1>
            <p>Supporting Indigenous people to safeguard our remaining biodiversity and intact ecosystems. Few people know about this, but it’s actually a really big deal — it could help save humanity.</p>

            <div className="image"><img src="https://camino.imgix.net/Indigenous-Conservation_hero.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>

            <div className="break"></div>

            <h1>Why</h1>
            <p>Indigenous people now own approximately 25% of the world’s land and safeguard approximately 80% of our remaining biodiversity. Since the 1970s, we’ve wiped out 60% of all wildlife on our planet. Indigenous people are key to helping protect what’s left. However, they need our support. Despite making up only 5% of the world’s population, Indigenous people account for 15% of the world’s extreme poor. By rising to the forefront of modern conservation, Indigenous people have the potential to become the lead custodians of our remaining biodiversity and intact ecosystems.</p>

            <div className="details">
              <div className="col">
                <table>
                  <thead>
                    <tr>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Indigenous people now own, manage or have tenure rights over 38 million square kilometres. This is 1/4 of the world’s land surface outside of Antarctica.</td>
                    </tr>
                    <tr>
                      <td>If forestland under secure tenure grows by 909 million acres by 2050, reduced deforestation could result in the avoidance of 6.1 gigatonnes of carbon dioxide emissions. This solution could bring the total forest area under Indigenous management to 2.2 billion acres, securing an estimated protected stock of 232 gigatonnes of carbon, roughly equivalent to over 850 gigatonnes of carbon dioxide if released into the atmosphere.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Indigenous people make up less than 5% of the global population yet make up 15% of the world’s poorest of the poor.</td>
                    </tr>
                    <tr>
                      <td>Supporting the management of this land could result in the protection of 849.37 gigatonnes of CO2.</td>
                    </tr>
                    <tr>
                      <td>About 65% of Indigenous lands have not been intensively developed, compared with 44% of other lands.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="break"></div>

            <h1>How we measure success</h1>
            <p>The number of jobs created in Indigenous conservation. The number of Indigenous people lifted out of extreme poverty in these local communities. The number of hectares under Indigenous conservation. The number of species removed from the endangered list.</p>
            <p>We cannot make change alone. Our trusted Indigenous conservation partners share a common vision and provide high impact, on the ground programs around the world.</p>
            <p className="credit">Confirmed partner:</p>
    
           <div className="partners">
              <div className="partner"><a href="https://www.countryneedspeople.org.au" target="_blank" rel="noopener noreferrer"><img src="https://camino.imgix.net/Why_03_Country.png" alt="Partner" /></a></div>
            
            </div>
    
            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break noline"></div>

            <CaminoSubscribe />

            </div>
          </section>
          
          <section>
            <div className="inner">
              <CaminoJumpToTopButton scrollStepInPx="50" delayInMs="10"/>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContentWhy;
