import React, { Component } from 'react';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import CaminoSubscribe from './CaminoSubscribe';
import { PageTracker } from './PageTracker';

class ContentWho extends Component {
  constructor(props) {
    super(props);

    this.props.onContentPage('who', true);
  }

  render() {
    return (
      <div className="page">

        <PageTracker path="/who" />

        <div className="caminoContent">
          <section>
            <div className="inner">

            <div className="message">
            <h2>We know many minds are<br/>better than one.</h2>
            </div>

            </div>
          </section>

          <section>
            <div className="inner">

            <h1>We are Camino.</h1>
            <p>We believe we have a responsibility to innovate — to find new expressions and solutions for common issues. Diverse life experiences and shared values inform our strategic thinking. As a collective, we have clearly defined responsibilities within Camino which helps us to be responsive, face emerging ideas, meet new challenges and mobilise opportunity.</p>

            <div className="people">
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_01-Bjorn.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Bjorn Everts</div>
                  <div className="title">Co-founder</div>
                  <div className="role">Director of impact and partnerships</div>
                  <p>
                  Bjorn is the co-founder of several international social enterprises and has worked with the United Nations and a range of not for profit and philanthropic organisations in education, community development and conservation in Africa, Europe and Australia. </p>
      <p>He spent the last five years as the CEO of the Karrkad Kanjdji Trust, a failing organisation he helped to grow into a multi award winning conservation organisation supporting indigenous led conservation, education, carbon abatement, cultural heritage management and community development across an area roughly the size of Switzerland. </p>
      <p>He holds a Masters degree in Sustainable Development from the University of London, a Bachelors degree in Psychology, is a qualified teacher and is a graduate of the Australian Institute of Company Directors.  
                  </p>
                  <p> <a className="secondary-link" href="mailto:bjorn@caminosocial.org">bjorn@caminosocial.org</a>
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_02-Tobin.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Tobin Lush</div>
                  <div className="title">Co-founder</div>
                  <div className="role">Director of strategy and design</div>
                  <p>
                  Tobin Lush is a fine art and film graduate. He
                  started his career in London, working in a number
                  of design agencies including the Getty Images
                  Global Studio and working across national and
                  global accounts including Orange, SEAT, Nike,
                  ITV Digital, Sky, World Vision and Cancer
                  Research UK. For Getty Images, Tobin developed
                  global brands and integrated multi-channel
                  global campaigns.
                  </p>
                  <p>
                  In 2005 he founded a small, multidisciplinary
                  design and communications studio in Adelaide
                  called Rodeo. The studio services global, national
                  and local clients. Tobin plays a key role in strategy,
                  design and content, while also embedding the
                  need for all businesses and organisations to
                  embrace responsible and sustainable business
                  agendas along with growth and participation.
                  </p>
                  <p>
                  Lush’s work has been recognised internationally
                  by awards such as the American Graphic Design
                  Award, How Design Interactive Award, Graphic
                  Design USA Design Award and multiple Good
                  Design Awards in Australia.
                  </p>
                  <p> <a className="secondary-link" href="mailto:tobin@caminosocial.org">tobin@caminosocial.org</a>
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_03-Michael.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Michael Fagan</div>
                  <div className="title">Co-founder</div>
                  <div className="role">Director of planning and technology</div>
                  <p>
                  With over 10 years of experience in IT consulting,
                  Michael has learned what works when it comes to
                  building high performing teams. He cares deeply
                  about curating humane work environments
                  where diversity of gender, cultural background
                  and thinking is a treasured asset. Michael is
                  a skilled facilitator and combines a variety of
                  techniques from executive coaching and agile
                  to unleash an organisations’ latent creativity
                  through compassion rather than coercion.
                  </p>
                  <p>
                  Michael has a BA in Mathematics and an MSc in
                  Machine Learning from Trinity College, Dublin.
                  Through his research he was an early pioneer
                  in the application of reinforcement learning in
                  computer games to create adaptive non-player
                  agents and rewarding experiences. With a strong
                  background in software development and a
                  passion for teamwork, Michael’s forte is in helping
                  teams make the skills and mindset shift required
                  when dealing with problems in a complex
                  domain.
                  </p>
                  <p>
                  Raised on the land, Michael has witnessed
                  first-hand the impact of climate change on hard
                  working families. Camino is his way to put his
                  skills to work on the biggest challenge of our time.
                  </p>
                  <p> <a className="secondary-link" href="mailto:mick@caminosocial.org">mick@caminosocial.org</a>
                  </p>
                </div>
              </div>
              
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_05-Sam.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Sam Bergin</div>
                  <div className="role">Senior developer</div>
                  <p>
                  Sam has nearly 20 years experience working
                  as a software engineer and a consultant.
                  He has worked with a variety of organisations
                  including not for profit, finance and retail both
                  in Australia and abroad. He has worked with
                  teams as part of start-ups as well as in enterprise
                  environments.
                  </p>
                  <p>
                  Sam likes lean/agile projects run by autonomous
                  teams that are passionate and share in the vision
                  for the product they’re building.
                  </p>
                  <p>
                  He is a father of three young girls and looks
                  forward to being part of something that will
                  result in positive, lasting change.
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/Who_Nikki.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Nikki Rydon</div>
                  <div className="role">Communications manager</div>
                  <p>
                  Nikki has been working as a producer, design manager, writer, actor, theatre maker, workshop facilitator and educator in the performing arts and culture industries for the past 15 years. During this time Nikki has co-created, written, produced and performed numerous award winning theatre works in both solo and ensemble capacities spanning environmental educational theatre, immersive sensory theatre and as an actor and permanent ensemble member with The Melbourne Playback Theatre Company, amongst others, touring various shows both nationally and internationally.                
                  </p>
                  <p>
                  In addition to her work in theatre, Nikki has been Creative Producer for strategic communications and design company Rodeo, in which she managed and produced both small and large scale projects in the arts, design, health, government and environment sectors, working across client and stakeholder liaison, community and corporate consultation and project management, while nurturing relationships with clients locally, nationally and internationally. With a deep love for the natural world and connecting with people, Nikki is delighted to be a part of Camino and its contribution as a mobilising force for positive change. 
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/Tom.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Thomas Ackling</div>
                  <div className="role">Developer</div>
                  <p>
                  My personal idea of success is to give the most in each interaction. 
                  By proactively using my Agile, and Software Development expertise to facilitate, lead, and grow others, we will make a difference. 
                  </p>
                  <p>
                  Enabling higher quality outcomes, connecting vision to delivery, and enhancing organisational culture.
                  </p>
                </div>
              </div>
            </div>
            

            <div className="image"><img src="https://camino.imgix.net/03_06_Event-image.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>            

            </div>
          </section>

          <section>
            <div className="inner">

            <div className="break"></div>

            <h2 className="quote">When we discover<br/>something that doesn’t<br/>work, we re-design it.</h2>

            </div>
          </section>

          <section>
            <div className="inner">

            <div className="break"></div>

            <h1>Our key <br/>advisors.</h1>
            <p>
            Our key advisors help us to imagine what is possible.
            They bring a wealth of creativity, experience and know-how to our
            team and above all they care deeply about what we are trying to
            achieve together.
            </p>

            <div className="people">
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_07-Thao.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Dr Thao Nguyen</div>
                  <div className="title">Vietnam</div>
                  <div className="role">Advisor Girls Education and Business</div>
                  <p>
                  Thao is a serial entrepreneur who currently
                  runs two multinational business and has work
                  experience across 23 countries.
                  </p>
                  <p>
                  She is a passionate advocate and expert
                  on women’s economic and educational
                  empowerment and has worked for the United
                  Nations, the European Union, the World Bank
                  and other international aid organizations as a
                  consultant on projects that empower women in
                  Africa, Central Asia, the Caucasus, South America
                  and the Pacific Islands.
                  </p>
                  <p>
                  She has a PhD in Business Information Systems
                  and was the recipient of the Newton International
                  Postdoctoral Fellowship to research the use of
                  technology to benefit women entrepreneurs.
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_08-Jacob.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Jacob Top Møller</div>
                  <div className="title">Denmark</div>
                  <div className="role">Futurist.</div>
                  <p>
                  Jacob is a business-focused techie, futurist, and
                  thinker at large. He has experience expanding the
                  footprint of fast-growing tech companies across
                  multiple countries, as well as advising large tech
                  organizations on how to plan for medium to long
                  term future scenarios.
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_09-Lloyd.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Lloyd Blum</div>
                  <div className="title">Madagascar</div>
                  <div className="role">Ecologist</div>
                  <p>
                  Lloyd is an applied ecologist and humanitarian
                  with a professional background in sustainable
                  agriculture and rural development. He has a
                  deep interest in restoration ecology for food and
                  nutrition security and has worked in numerous
                  developing countries in this capacity.
                  </p>
                  <p>
                  For the past seven years, he has spent time
                  working between Germany, Madagascar and
                  Ethiopia.
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_10-Marje.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Dr Marije Geldof</div>
                  <div className="title">Malawi/Netherlands</div>
                  <div className="role">Advisor Girls Education and Technology</div>
                  <p>
                  Marije has a background in artificial intelligence
                  and the use of technology for international
                  development. She has worked for a variety of
                  not for profit organisations, multilateral aid
                  organisations and universities.
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_11-Maciej.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Maciej Sudra</div>
                  <div className="title">Kenya</div>
                  <div className="role">Educator/Social Entrepreneur</div>
                  <p>
                  Maciej holds degrees from MIT and Harvard
                  University. He is a passionate and creative
                  educator, conservationist and the founder of
                  several inspiring social enterprises.
                  </p>
                </div>
              </div>
              <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_12-Marnie.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Dr Marnie Telfer </div>
                  <div className="title">Australia</div>
                  <div className="role">Carbon Specialist</div>
                  <p>
                  Marnie holds a Bachelor and PhD in Chemical engineering and is an experienced carbon and greenhouse gas specialist. She has worked extensively auditing and carrying out project and methodology development of carbon abatement projects across the land sector, including environmental planting, regeneration, avoided deforestation and savanna fire management projects.
                  </p>
                  <p>
                  Marnie is passionate about sustainable and environment land management and conservation, particularly on indigenous-owned lands and has assessed projects in Brazil, Zimbabwe, Papua New Guinea and Australia.
                  </p>
                  <p>
                  She currently works as the Senior Carbon Coordinator with the Indigenous Land and Sea Corporation under the Australian Federal Government, and also assists on a community reforestation project in Baguia, Timor Leste.                    
                  </p>
                </div>
              </div>
             <div className="person">
                <div className="content">
                  <div className="image"><img src="https://camino.imgix.net/03_15-Anna.jpg?fm=jpg&q=80" alt="Name of person" /></div>
                  <div className="name">Anna Rigosi</div>
                  <div className="title">Australia/Italy</div>
                  <div className="role">Climate Scientist</div>
                  <p>
                  Anna led award winning global research on the effects of climate change on our worlds drinking water and fresh water habitats. She is an experienced academic and a passionate ecologist and ecological modeller but her path is now shifting towards education, activism and engaging the broader public in the urgency of our current climate crisis. 
                  </p>
                  <p>
                  She holds a PhD in Environmental Science and Technology and a Master in Environmental Hydraulics. She has recently become a mother and is studying towards a Master of Education.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="image"><img src="https://camino.imgix.net/03_13-Forest.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>            

            </div>
          </section>

          <section>
            <div className="inner">

            <div className="break"></div>

            <div className="image"><img src="https://camino.imgix.net/03_14-Meet-ryder.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>            

            <div className="break noline"></div>

            <h1>Meet<br/>Ryder.</h1>

            <p>
            He’s 12, is Australian and currently at Primary School.
            Since before he could speak, Ryder loved being in nature and
            it influences every part of his life. He has a passion for stories
            and illustration and spends hours making graphic novels
            about the future.
            </p>
            <p>
            He loves music, skateboards and making art. Ryder cares deeply
            for people and the natural world, it grounds him in moments
            of anxiety and fuels his unending imagination, he knows our
            collective future depends on it.
            </p>
            <p className="credit">
            Ryder is for Camino. He is photographed on the edge of Petrel Cove in South Australia,
            overlooking the Southern Ocean towards Antarctica.
            </p>

            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break noline"></div>

            <CaminoSubscribe />

            </div>
          </section>
          
          <section>
            <div className="inner">
              <CaminoJumpToTopButton scrollStepInPx="50" delayInMs="10"/>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContentWho;
