import React, { Component } from 'react';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import CaminoSubscribe from './CaminoSubscribe';
import { PageTracker } from './PageTracker';

class ContentImpact extends Component {
  constructor(props) {
    super(props);

    this.props.onContentPage('impact', true);
  }

  render() {
    return (
      <div className="page">

        <PageTracker path="/impact" />

        <div className="caminoContent">

          <section>
            <div className="inner">

            <div className="message">
            <h2>Here’s how the<br/>numbers tell the story.</h2>
            </div>

            </div>
          </section>

          <section>
            <div className="inner">

            <h1>We believe in<br/>simple and scalable.</h1>
            <p>
            Research and data modelling provides the framework for the forecasts outlined below. It’s important to note these figures don’t allow for directing revenue percentages into organisational expenditure and growth‚ which will be required. The numbers are included as they are to illustrate the ‘big picture’ opportunity in connecting global network effect and project impact.  
      </p>

            <div className="break"></div>

            <h1>
            <span className="highlight">3.196 billion social </span> media users in 2019, up 9% year-on-year*.
            </h1>
            <p>
            Social is big and growing. We have a goal of 1%+ conversion rate to
            Camino over a 4–8 year period. This would provide a significant network
            value creation, with a distinct global community supporting and
            engaging in our high impact goals.
            </p>
            <p className="credit">
            * The global state of digital in 2019 - https://hootsuite.com/resources/digital-in-2019
            </p>

            <div className="break"></div>

            <h1>
            <span className="highlight">277,777,750</span> Camino users worldwide over the next 3 years.
            </h1>
            <p>Our modelling forecasts that we have the potential to have over 250 million users in the next 3 years. Camino is designed from the ground up for users, for people in our high impact projects, our shared environment and humanity. We know our values are shared around the world and there’s an appetite for meaningful participation on an ethical platform that gives back to society.</p>

            <div className="break"></div>

            <h1>
            <span className="highlight">$262 million</span> cumulative profit in 3 years.
            </h1>
              <p>Every time you’re on Camino you’re contributing to people and the planet. This is one of the things we love the most — providing a platform where something ordinary has an extraordinary outcome. Camino is technology for good, bringing together a global community of people who contribute to shaping a better future for all people by simply communicating with each other.</p>
            <div className="break"></div>

            <h1>
            <span className="highlight">60% of users</span> access the platform every day.
            </h1>
            <p>
            This statistic is from the 1 billion monthly active users of Instagram
            worldwide. If this level of access was matched or exceeded by Camino
            we would have significantly high engagement and repeat participation
            within our Camino community, with our causes, our project partners
            and on the ground programs.
            </p>

            <div className="break"></div>

            <h1>
            <span className="highlight">100% of revenue</span> over the next 4 years could exceed $2.5 billion.
            </h1>
            <p>Intrinsic motivation and network effect could significantly grow the Camino user base to achieve critical mass. Based on the modelling, Camino users could reach 250 million over the next 4 years. Users donate $10 each year to be on our platform — that’s less than 3 cents a day. Currently social user numbers are growing 9% year on year.</p>
            <div className="break"></div>

            <h1>
            <span className="highlight">139 million hectares</span> of Indigenous conservation over the next 4 years.
            </h1>
            <p>
            $1 million per year will support an indigenous protected area of approximately 1.6 million hectares. Beyond carbon sequestration, indigenous land management conserves biodiversity, maintains a range of ecosystems services, safeguards rich cultures and traditional ways of life, and responds to the needs of the most vulnerable.
            </p>

            <div className="break"></div>

            <h1>
            <span className="highlight">116 million trees</span> planted over the next 4 years.            
            </h1>
            <p>Trees are amazing! To get an average cost of planting we’ve considered both Project GreenHands at $1.4/tree, and Eden Projects at $0.1/tree to get to $0.75 per tree. Our project partners not only help reforest areas most in need on the planet, but also contribute to empowering forest-dependent local communities who are trained and provided with tools for their livelihood development.
            </p>

            <div className="break"></div>

            <h1>
            <span className="highlight">23 million tonnes</span> of carbon abated and sequestered each year over 4 years.
            </h1>
            <p>Let’s put this into perspective, Australia’s largest forest carbon sink generates only 300,000 tonnes of carbon sequestration. Our calculation includes up to 8.7 million tonnes of carbon from educating girls, 8.7 million tonnes through Indigenous land management and 7.8 million tonnes by planting trees.</p>
            <p>
            Here’s the breakdown:
            </p>
            <ul className="list">
              <li>Educating girls: $10 per tonne of carbon / 87 million* = 8.7 million tonnes </li>
              <li>100,000 tonnes of carbon per indigenous protected area x 87 = 8.7 million tonnes www.warddeken.com/about 
</li>
              <li>Trees = 15 trees per tonne of carbon / 116 million trees 
= 7.8 million tonnes www.carbonneutral.com.au/faqs</li>
            </ul>
            <p className="credit">
            *From Hawken, P. (2017) Drawdown: The Most Comprehensive Plan Ever Proposed 
to Reverse Global Warming. New York, New York: Penguin Books. Page 81.
            </p>
    
            <div className="break"></div>

            <h1>
            <span className="highlight">How did we get these numbers?</span>            
            </h1>
            <p>
            We asked our networks, most of who share our values and are likely to be early adopters. Of the people who responded, 57% would join our social media network and 73% would recommend it to their friends. To calculate the network effect i.e. the number of people each Camino user would bring, we asked ‘how many people from their existing social network would join Camino based on their recommendation?’ Our results indicate that each Camino user would bring an expected 
total of 10 new users. 
            </p>
           <p className="credit">
            *Please note, impact outcomes articulated above are subject to variation in relation to revenue distribution percentages, organisational costs of Camino, partner relationships and global programs. 
            </p>
            <br/>
            <div className="image"><img src="https://camino.imgix.net/04_01-Landscape.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>

            <div className="break"></div>

            <div className="image"><img src="https://camino.imgix.net/04_02-Town.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>

            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break noline"></div>

            <CaminoSubscribe />

            </div>
          </section>

          <section>
            <div className="inner">
              <CaminoJumpToTopButton scrollStepInPx="50" delayInMs="10"/>
            </div>
          </section>          
        </div>
      </div>
    );
  }
}

export default ContentImpact;
