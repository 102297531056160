import React, { Component } from 'react';
import CaminoSubscribe from './CaminoSubscribe';
import CaminoFAQ from './CaminoFAQ';
import CaminoPreRoll from './CaminoPreRoll';
import CaminoVideo from './CaminoVideo';
import CaminoJumpToTopButton from './CaminoUtils/CaminoJumpToTopButton';
import { PageTracker } from './PageTracker'

class ContentAbout extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.props.onContentPage('about', false);
  }

  render() {
    const showPreRoll = this.props.showPreRoll;

    return (
      <div className="page">

        <PageTracker path="/about" />

        {showPreRoll &&
          <CaminoPreRoll />
        }

        <CaminoVideo />

        <div className="caminoContent">
          <section>
            <div className="inner">
            <h1>What if social media could give back to society?</h1>
            <p>We’re creating an exceptional mobile-first social platform designed from the ground up to bring people together and create a better world by contributing to some of the most inspiring conservation initiatives of our time.
            </p>So what is this?<p>
            Social media platforms have become synonymous with mistrust and the exploitation of data. We have a new vision. We won’t sell your personal data, flood you with advertising for junk that ends up in landfill, or let algorithms decide what you see and who to follow.
            </p>
            <div className="phone"><img src="https://camino.imgix.net/Camino_Mobile_V2.png" alt="Camino App" /></div>
            <p>We will donate 100% of our profits to climate action by planting trees, creating carbon credits and conserving forests.</p>
            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break"></div>
            <h1>Communication among friends should lift the spirits.</h1>
            <p>The basic purpose of a social platform is to provide connection and community. But imagine if it was truly designed for good — it could provide so much more. With this strategic purpose Camino will, in a life-enhancing way, bring together a global community to easily participate in reducing global warming by caring for other people and the planet.</p>
            <h2 className="quote">Imagine being able to do something ordinary that was truly extraordinary.</h2>
            <div className="image"><img src="https://camino.imgix.net/01_02-Field.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>
            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break"></div>
            <h1>Be<br/>involved.</h1>
              <p>Want to help create a better world by being part of a global community that cares for other people and our planet?</p>
              <p>Want to be part of something truly amazing and scalable – where your swipe or click can result in multiplying positive change?</p>
              <p>Want to give back to society while still staying connected with friends on social like you’re used to?</p>
              <p>Now you can.</p>
              <p>Camino is an ethical, human approach to mobile first social networking that is responsible, honest and makes a difference.</p>
              <p>This is no small undertaking, but we are serious about making it happen. With your help we believe it’s possible to offer an alternative.</p>
            <div className="image"><img src="https://camino.imgix.net/01_03-Soccer.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>
            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break"></div>
            <h1>Knowledge<br/>is power.</h1>
            <p>It’s right to have questions and to provide answers.</p>

            <CaminoFAQ />

            <div className="image"><img src="https://camino.imgix.net/01_04-Students.jpg?fm=jpg&w=1280&q=80" alt="Placeholder" /></div>
            </div>
          </section>

          <section>
            <div className="inner">
            <div className="break noline"></div>

            <CaminoSubscribe />

            </div>
          </section>

          <section>
            <div className="inner">
              <CaminoJumpToTopButton />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContentAbout;
